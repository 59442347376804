(function ($) {

  $(document).ready(function () {

    $('.i18n-en .block-search .form-item-search-block-form input.form-text').attr("placeholder", "Search").val('');
    $('.i18n-fr .block-search .form-item-search-block-form input.form-text').attr("placeholder", "Chercher").val('');
    $('.i18n-pt .block-search .form-item-search-block-form input.form-text').attr("placeholder", "Buscar").val('');

    $('.menu-name-main-menu > ul.menu').addClass('sm').addClass('sm-simple').smartmenus({
      subMenusMinWidth: '12em',
      mainMenuSubOffsetX: 0,
      mainMenuSubOffsetY: 0,
      subMenusSubOffsetX: 0,
      subMenusSubOffsetY: 0,
      markCurrentItem: true
    });

    $(function () {
      var $mainMenuState = $('#main-menu-state');
      if ($mainMenuState.length) {
        // animate mobile menu
        $mainMenuState.change(function (e) {
          var $menu = $('#main-menu');
          if (this.checked) {
            $menu.hide().slideDown(250, function () {
              $menu.css('display', '');
            });
          } else {
            $menu.show().slideUp(250, function () {
              $menu.css('display', '');
            });
          }
        });
        // hide mobile menu beforeunload
        $(window).bind('beforeunload unload', function () {
          if ($mainMenuState[0].checked) {
            $mainMenuState[0].click();
          }
        });
      }
    });

    $('.not-front .view .view .view-filters form').attr('action', window.location.pathname);

    $('.view-links').closest('.section-row').addClass('match-heights');

    $('.view-promo').closest('.section-row, .side-row').addClass('section-with-promo');

    $('.view-slider').closest('.section-row, .side-row').addClass('section-with-slider');

    $('.views-row.tagged-content-slider').addClass('view-type-slider');

    $('.view-slider').each(function () {
      $('.view-content', this).slick({
        fade: true,
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        adaptiveHeight: false,
        appendArrows: $('.slick-nav', this),
        appendDots: $('.slick-nav', this),
        responsive: [
          {
            breakpoint: 480,
            settings: {
              fade: false
            }
          }
        ]
      });
    });

    $('.slick-nav').closest('.view-footer').addClass('slick-nav-wrapper');
    $('.slick-controls').closest('.view-footer').addClass('slick-controls-wrapper');

    // Credits expanded
    $('.credit, .vid-description').click(function () {
      $(this).toggleClass("collapsed");
    });
    $('.credit').closest('.card-image-et').addClass('credit-wrapper');
    $('.credit').closest('.card-banner-image-et').addClass('credit-wrapper');

    // Section row classes
    $('.view-banner').closest('.section-row').addClass('section-with-banner');

    $('.bg-video').closest('.section-row').addClass('row-bg-video');


    $('.card:has(div.card-image)').addClass('card-with-image');
    $('.custom-link-flexi div.card:has(div.card-image)').addClass('card-with-image');
    $('.card:has(div.card-photo)').addClass('card-with-image');
    $('.card:has(div.card-logo)').addClass('card-with-image');


    $('.section-row .card-photos').closest('.section-row').addClass('section-with-gallery');
    $('.side-row .card-photos').closest('.side-row').addClass('section-with-gallery');
    $('.card-photos').each(function () {
      $('ul', this).slick({
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        centerMode: true,
        variableWidth: true,
        appendArrows: $('.slick-controls', this),
        appendDots: $('.slick-controls', this)
      });
    });

    $('.view-type-slider:not(.tagged-content-slider) .section-inner .view:not(.view-tagged-content)').append('<div class="view-footer"><div class="slick-controls"></div></div>');
    $('.view-type-slider.tagged-content-slider .section-inner .view-content .view.view-tagged-content').append('<div class="view-footer"><div class="slick-controls"></div></div>');

    $('.row-max-6.view-type-slider:not(.side-row):not(.tagged-content-slider)').each(function () {
      $('.view .view-content', this).slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 6,
        slidesToScroll: 6,
        appendArrows: $('.slick-controls', this),
        appendDots: $('.slick-controls', this),
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 860,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              infinite: false,
              adaptiveHeight: true
            }
          }
        ]
      });
    });
    $('.row-max-4.view-type-slider:not(.side-row):not(.tagged-content-slider)').each(function () {
      $('.view .view-content', this).slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        appendArrows: $('.slick-controls', this),
        appendDots: $('.slick-controls', this),
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 720,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              infinite: false,
              adaptiveHeight: true
            }
          }
        ]
      });
    });
    $('.row-max-3.view-type-slider:not(.side-row):not(.tagged-content-slider)').each(function () {
      $('.view .view-content', this).slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 3,
        appendArrows: $('.slick-controls', this),
        appendDots: $('.slick-controls', this),
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 720,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              infinite: false,
              adaptiveHeight: true
            }
          }
        ]
      });
    });
    $('.row-max-2.view-type-slider:not(.side-row):not(.tagged-content-slider)').each(function () {
      $('.view .view-content', this).slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 2,
        appendArrows: $('.slick-controls', this),
        appendDots: $('.slick-controls', this),
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 720,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              infinite: false,
              adaptiveHeight: true
            }
          }
        ]
      });
    });
    $('.row-max-1.view-type-slider:not(.side-row):not(.tagged-content-slider)').each(function () {
      $('.view .view-content', this).slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendArrows: $('.slick-controls', this),
        appendDots: $('.slick-controls', this),
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 720,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              infinite: false,
              adaptiveHeight: true
            }
          }
        ]
      });
    });

    // TAGGED CONTENT SLIDERS //

    $('.row-max-6.view-type-slider.tagged-content-slider:not(.side-row)').each(function () {
      $('.view.view-tagged-content .view .view-content', this).slick({
        rows: 1,
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 6,
        slidesToScroll: 6,
        appendArrows: $('.slick-controls', this),
        appendDots: $('.slick-controls', this),
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              rows: 1,
              slidesToShow: 6,
              slidesToScroll: 6,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 860,
            settings: {
              rows: 2,
              slidesToShow: 3,
              slidesToScroll: 3,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              rows: 6,
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              infinite: false,
              adaptiveHeight: true
            }
          }
        ]
      });
    });
    $('.row-max-4.view-type-slider.tagged-content-slider:not(.side-row)').each(function () {
      $('.view.view-tagged-content .view .view-content', this).slick({
        rows: 1,
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        appendArrows: $('.slick-controls', this),
        appendDots: $('.slick-controls', this),
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              rows: 1,
              slidesToShow: 4,
              slidesToScroll: 4,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 720,
            settings: {
              rows: 2,
              slidesToShow: 2,
              slidesToScroll: 2,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              rows: 4,
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              infinite: false,
              adaptiveHeight: true
            }
          }
        ]
      });
    });
    $('.row-max-3.view-type-slider.tagged-content-slider:not(.side-row)').each(function () {
      $('.view.view-tagged-content .view .view-content', this).slick({
        rows: 1,
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 3,
        appendArrows: $('.slick-controls', this),
        appendDots: $('.slick-controls', this),
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              rows: 1,
              slidesToShow: 3,
              slidesToScroll: 3,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 720,
            settings: {
              rows: 3,
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              rows: 3,
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              infinite: false,
              adaptiveHeight: true
            }
          }
        ]
      });
    });
    $('.row-max-2.view-type-slider.tagged-content-slider:not(.side-row)').each(function () {
      $('.view.view-tagged-content .view .view-content', this).slick({
        rows: 1,
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 2,
        appendArrows: $('.slick-controls', this),
        appendDots: $('.slick-controls', this),
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              rows: 1,
              slidesToShow: 2,
              slidesToScroll: 2,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 720,
            settings: {
              rows: 1,
              slidesToShow: 2,
              slidesToScroll: 2,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              rows: 2,
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              infinite: false,
              adaptiveHeight: true
            }
          }
        ]
      });
    });
    $('.row-max-1.view-type-slider.tagged-content-slider:not(.side-row)').each(function () {
      $('.view.view-tagged-content .view .view-content', this).slick({
        rows: 1,
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendArrows: $('.slick-controls', this),
        appendDots: $('.slick-controls', this),
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              rows: 1,
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 720,
            settings: {
              rows: 1,
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              rows: 1,
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              infinite: false,
              adaptiveHeight: true
            }
          }
        ]
      });
    });


    $('.view-twitter:not(.side-row .view-twitter)').each(function () {
      $('.view-content', this).slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        appendArrows: $('.slick-controls', this),
        appendDots: $('.slick-controls', this),
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 720,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
              infinite: false
            }
          }
        ]
      });
    });

    $('.side-row .view-twitter').each(function () {
      $('.view-content', this).slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendArrows: $('.slick-controls', this),
        appendDots: $('.slick-controls', this),
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 720,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
              infinite: false
            }
          }
        ]
      });
    });

    $('.side-row.view-type-slider:not(.tagged-content-slider)').each(function () {
      $('.view-content', this).slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendArrows: $('.slick-controls', this),
        appendDots: $('.slick-controls', this),
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 720,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
              infinite: false
            }
          }
        ]
      });
    });

    $('.side-row.view-type-slider.tagged-content-slider').each(function () {
      $('.view.view-tagged-content .view .view-content', this).slick({
        rows: 4,
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendArrows: $('.slick-controls', this),
        appendDots: $('.slick-controls', this),
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              rows: 4,
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 720,
            settings: {
              rows: 4,
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              rows: 4,
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
              infinite: false
            }
          }
        ]
      });
    });

    $('.view-footer-sections .view-type-slider .card-organisations .item-list').append('<div class="view-footer"><div class="slick-controls"></div></div>');
    $('.view-footer-sections .row-max-6.view-type-slider').each(function () {
      $('.card-organisations ul', this).slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 6,
        slidesToScroll: 6,
        appendArrows: $('.slick-controls', this),
        appendDots: $('.slick-controls', this),
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 6,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 720,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              infinite: false,
              adaptiveHeight: true
            }
          }
        ]
      });
    });
    $('.view-footer-sections .row-max-4.view-type-slider').each(function () {
      $('.card-organisations ul', this).slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        appendArrows: $('.slick-controls', this),
        appendDots: $('.slick-controls', this),
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 720,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              infinite: false,
              adaptiveHeight: true
            }
          }
        ]
      });
    });
    $('.view-footer-sections .row-max-3.view-type-slider').each(function () {
      $('.card-organisations ul', this).slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 3,
        appendArrows: $('.slick-controls', this),
        appendDots: $('.slick-controls', this),
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 720,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              infinite: false,
              adaptiveHeight: true
            }
          }
        ]
      });
    });
    $('.view-footer-sections .row-max-2.view-type-slider').each(function () {
      $('.card-organisations ul', this).slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 2,
        appendArrows: $('.slick-controls', this),
        appendDots: $('.slick-controls', this),
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 720,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              infinite: false,
              adaptiveHeight: true
            }
          }
        ]
      });
    });
    $('.view-footer-sections .row-max-1.view-type-slider').each(function () {
      $('.card-organisations ul', this).slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendArrows: $('.slick-controls', this),
        appendDots: $('.slick-controls', this),
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 720,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              infinite: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              infinite: false,
              adaptiveHeight: true
            }
          }
        ]
      });
    });


    function scaleA4() {
      $('.view-sections img:not(.view-sections .view img)').each(function () {
        $(this).load(function () {
          var ar = $(this).width() / $(this).height();
          if ((ar > 7 / 10) && (ar < 70 / 98)) {
            $(this).css({height: '100%', maxWidth: '420px'});
          }
        });
      });
    }

    scaleA4();

    function createCaptions() {
      $('.view-sections img:not(.view-sections .view img, .view-sections .caption img)').each(function () {
        if ($(this).attr('title')) {
          var caption = $(this).attr('title');
          if ($(this).parent('a').length) {
            $(this).parent('a').wrapAll('<span class="caption"/>').after('<p>' + caption + '</p>');
            $(this).load(function () {
              var width = $(this).width();
              $(this).closest('.caption').find('p').width(width);
            });
          } else {
            $(this).wrapAll('<span class="caption"/>').after('<p>' + caption + '</p>');
            $(this).load(function () {
              var width = $(this).width();
              $(this).next('p').width(width);
            });
          }
        }
      });
    }

    createCaptions();

    function updateCaptions() {
      $('.view-sections .caption img').each(function () {
        var width = $(this).width();
        if ($(this).parent('a').length) {
          $(this).closest('.caption').find('p').width(width);
        } else {
          $(this).next('p').width(width);
        }
      });
    }


    /* Feed nugget match heights option */
    function equalHightsTick() {
      $('.card-select-feed').each(function () {
        $(this).siblings('.card-equal-heights').hide(); // move to css when theme is ready
        var isEnabled = $(this).siblings('.card-equal-heights').text().replace(/\s/g, '');
        if (isEnabled == '1') {
          // selectors below have to be checked as the views are different..
          $('.view-content, .view-content ul, .view-content-main > .views-row, .view-content-main .view-content > .views-row', this).addClass('match-heights');
        }
      });

      $('.card-tagged-view').each(function () {
        $(this).siblings('.card-equal-heights').hide(); // move to css when theme is ready
        var isEnabled = $(this).siblings('.card-equal-heights').text().replace(/\s/g, '');
        if (isEnabled == '1') {
          // selectors below have to be checked as the views are different..
          $('.view-content, .view-content ul, .view-content-main > .views-row, .view-content-main .view-content > .views-row', this).addClass('match-heights');
        }
      });

      $('.section-inner').each(function () {
        $(this).children('.card-equal-heights').hide(); // move to css when theme is ready
        var isEnabled = $(this).children('.card-equal-heights').text().replace(/\s/g, '');
        if (isEnabled == '1') {
          $(this).parent('.views-row').addClass('match-heights');
        } else if (isEnabled == '0') {
          $(this).parent('.views-row').removeClass('match-heights');
        }
      });
    }

    equalHightsTick();

    /* MATCH HEIGHTS SCRIPT*/
    function matchAllHeights() {
      $('.match-img-heights .image-height').matchHeight();
      $('.match-img-heights .card-logo').matchHeight();
      $('.match-card-heights .card-height').matchHeight();
      //$('.match-heights .card').matchHeight();
      $('.match-heights .card-text').matchHeight();
    }

    matchAllHeights();

    $(document).ajaxSuccess(function () {
      equalHightsTick();
      matchAllHeights();
    });

    var $win = $(window);
    var lastWinWidth = $win.width();
    $win.resize(function () {
      clearTimeout($win.windowResized);
      $win.windowResized = setTimeout(function () {
        var winWidth = $win.width();
        if (lastWinWidth !== winWidth) {
          lastWinWidth = winWidth;
          /* RUN THE FOLLOWING FUNCTIONS ON WINDOW RESIZE */
          equalHightsTick();
          matchAllHeights();
          scaleA4();
          updateCaptions();
          /* ------------------- EOL -------------------- */
        }
      }, 250);
    });


    var $doc = $('html, body');
    $('a[href^="#anchor-"]').click(function () {
      var href = $.attr(this, 'href');
      $doc.animate({
        scrollTop: $(href).offset().top
      }, 500, function () {
        window.location.hash = href;
      });
      return false;
    });

    $('.views-exposed-widgets select').each(function () {
      if (($('option', this).length == 1) && ($('option', this).val() == 'All')) {
        $(this).closest('.views-exposed-widget').hide();
      } else {
        $(this).closest('.views-exposed-widget').show();
      }
    });


    Drupal.behaviors.remora = {
      attach: function (context, settings) {

        $('.views-exposed-widgets select', context).each(function () {
          if ((($('option', this).length == 1) && ($('option', this).val() == 'All')) || ($('option', this).length == 0)) {
            $(this).closest('.views-exposed-widget').hide();
          } else {
            $(this).closest('.views-exposed-widget').show();
          }
        });

        /*
        $('.view-slider', context).closest('.section-row').addClass('section-with-slider');
        $('.view-slider', context).each(function() {
          $('.view-content', this).slick({
            fade:true,
            dots:true,
            infinite:true,
            speed:300,
            slidesToShow:1,
            adaptiveHeight:false,
            appendArrows:$('.slick-nav', this),
            appendDots:$('.slick-nav', this)
          });
        });

        $('.section-row .card-photos', context).closest('.section-row').addClass('section-with-gallery');
        $('.card-photos', context).each(function() {
          $('ul', this).slick({
            dots:true,
            infinite:false,
            speed:300,
            slidesToShow:1,
            centerMode:true,
            variableWidth:true,
            appendArrows:$('.slick-controls', this),
            appendDots:$('.slick-controls', this)
          });
        });

        $('.view-type-slider .section-inner .view', context).append('<div class="view-footer"><div class="slick-controls"></div></div>');
        $('.row-max-4.view-type-slider', context).each(function() {
          $('.view .view-content', this).slick({
            dots:true,
            infinite:true,
            speed:300,
            slidesToShow:4,
            slidesToScroll:4,
            appendArrows:$('.slick-controls', this),
            appendDots:$('.slick-controls', this),
            responsive: [
              {
                breakpoint:960,
                settings: {
                  slidesToShow:3,
                  slidesToScroll:3,
                  dots:true,
                  infinite:true
                }
              },
              {
                breakpoint:720,
                settings:{
                  slidesToShow:3,
                  slidesToScroll:3,
                  dots:true,
                  infinite:true
                }
              },
              {
                breakpoint:480,
                settings: {
                  slidesToShow:1,
                  slidesToScroll:1,
                  dots:true,
                  infinite:false,
                  adaptiveHeight:true
                }
              }
            ]
          });
        });
        $('.row-max-3.view-type-slider', context).each(function() {
          $('.view .view-content', this).slick({
            dots:true,
            infinite:true,
            speed:300,
            slidesToShow:3,
            slidesToScroll:3,
            appendArrows:$('.slick-controls', this),
            appendDots:$('.slick-controls', this),
            responsive: [
              {
                breakpoint:960,
                settings: {
                  slidesToShow:3,
                  slidesToScroll:3,
                  dots:true,
                  infinite:true
                }
              },
              {
                breakpoint:720,
                settings:{
                  slidesToShow:2,
                  slidesToScroll:2,
                  dots:true,
                  infinite:true
                }
              },
              {
                breakpoint:480,
                settings: {
                  slidesToShow:1,
                  slidesToScroll:1,
                  dots:true,
                  infinite:false,
                  adaptiveHeight:true
                }
              }
            ]
          });
        });
        $('.row-max-2.view-type-slider', context).each(function() {
          $('.view .view-content', this).slick({
            dots:true,
            infinite:true,
            speed:300,
            slidesToShow:2,
            slidesToScroll:2,
            appendArrows:$('.slick-controls', this),
            appendDots:$('.slick-controls', this),
            responsive: [
              {
                breakpoint:960,
                settings: {
                  slidesToShow:2,
                  slidesToScroll:2,
                  dots:true,
                  infinite:true
                }
              },
              {
                breakpoint:720,
                settings:{
                  slidesToShow:1,
                  slidesToScroll:1,
                  dots:true,
                  infinite:true
                }
              },
              {
                breakpoint:480,
                settings: {
                  slidesToShow:1,
                  slidesToScroll:1,
                  dots:true,
                  infinite:false,
                  adaptiveHeight:true
                }
              }
            ]
          });
        });

        $('.view-twitter', context).each(function() {
          $('.view-content', this).slick({
            dots:true,
            infinite:true,
            speed:300,
            slidesToShow:4,
            slidesToScroll:4,
            appendArrows:$('.slick-controls', this),
            appendDots:$('.slick-controls', this),
            adaptiveHeight:true,
            responsive: [
              {
                breakpoint:960,
                settings: {
                  slidesToShow:3,
                  slidesToScroll:3,
                  dots:true,
                  infinite:true
                }
              },
              {
                breakpoint:720,
                settings:{
                  slidesToShow:2,
                  slidesToScroll:2,
                  dots:true,
                  infinite:true
                }
              },
              {
                breakpoint:480,
                settings: {
                  slidesToShow:1,
                  slidesToScroll:1,
                  dots:false,
                  infinite:false
                }
              }
            ]
          });
        });
        */

        $('.not-front .view .view .view-filters form').attr('action', window.location.pathname);

        $(window).trigger('resize'); //to activate matchHeights


      }
    };


    jQuery(document).ajaxSend(function (event, xhr, settings) {
      if ((Drupal.settings.views) && (Drupal.settings.views.ajaxViews)) {
        $('body').addClass('ajax-processing');
      }
      //console.log('Sending...');
    });

    jQuery(document).ajaxComplete(function (event, xhr, settings) {
      if ((Drupal.settings.views) && (Drupal.settings.views.ajaxViews)) {
        $('body').removeClass('ajax-processing');
      }
      //console.log('Complete!');
    });


    //  FAQ COLLAPSE/EXPAND
    $('.card-qa-question').click(function (e) {
      e.preventDefault();
      var notthis = $('.active').not(this);
      notthis.toggleClass('active').next('.card-qa-answer').slideToggle(300);
      $(this).toggleClass('active').next().slideToggle("fast");
    });

    //$(".view-type-slider .slick-controls:empty").closest('.view-footer').hide(); /* this is incomplete... */

    //Show/hide summary classes
    $('div.card-text:has(div.card-summary-wrapper)').addClass('card-with-summary');


    $.fn.isInViewport = function () {
      var elementTop = $(this).offset().top;
      var elementMiddle = elementTop + $(this).outerHeight() / 2;
      var viewportTop = $(window).scrollTop();
      var viewportBottom = viewportTop + $(window).height();
      return elementMiddle > viewportTop && elementMiddle < viewportBottom;
    };


    $(window).on('resize scroll', function () {
      $('.bg-video video').each(function () {
        if ($(this).isInViewport()) {
          $(this)[0].play();
        } else {
          $(this)[0].pause();
        }
      });
    });


  });

})(jQuery);
